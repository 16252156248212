import { Heading } from "@flixbus/honeycomb-react";
import { ErrorPage, ErrorTypes } from "@flixbus-phx/marketplace-common";
import useAxios from "axios-hooks";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import BrandPageHeader from "../../components/brandPageHeader/BrandPageHeader";
import BrandPolicies from "../../components/brandPolicies/BrandPolicies";
import BrandPoliciesNavigation from "../../components/brandPoliciesNavigation/BrandPoliciesNavigation";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import PartnerPolicies from "../../components/partnerPolicies/PartnerPolicies";
import PoliciesSkeleton from "../../components/policiesSkeleton/PoliciesSkeleton";
import { BrandData, BrandPolicyType } from "../../shared/apiTypes/apiTypes";
import {
  brandPolicyTitles,
  partnerPolicyTitles,
} from "../../shared/helpers/accordionTitles/accordionTitles";
import * as css from "./Brand.scss";

const Brand: React.FC = () => {
  const { brand } = useParams();

  const [{ data, loading, error }] = useAxios<BrandData>(
    `${process.env.API!!}/${brand}.json`
  );

  if (loading) {
    return (
      <div aria-live="polite" aria-busy="true">
        <Breadcrumbs />
        <BrandPageHeader />
        <PoliciesSkeleton titles={partnerPolicyTitles} />
        <Heading extraClasses={css.heading2} size={2}>
          <FormattedMessage id="general.policies" />
        </Heading>
        <PoliciesSkeleton titles={brandPolicyTitles} />
      </div>
    );
  }

  if (error) return <ErrorPage type={ErrorTypes.e404} />;

  if (data) {
    return (
      <div aria-busy="false">
        <Breadcrumbs title={data?.brandName} />
        <BrandPageHeader
          brandName={data.brandName}
          legalCompanyName={data.legalCompanyName}
        />
        <PartnerPolicies policies={data.partnerPolicies} />
        <Heading extraClasses={css.heading2} size={2}>
          <FormattedMessage id="general.policies" />
        </Heading>

        {data.brandPolicies.policies.length === 2 ? (
          <BrandPoliciesNavigation
            DomesticPolicies={
              <BrandPolicies
                policies={
                  data.brandPolicies.policies.find(
                    ({ type }) => type === BrandPolicyType.DOMESTIC
                  )!
                }
                policyUnits={data.brandPolicies.policyUnits}
              />
            }
            InternationalPolices={
              <BrandPolicies
                policies={
                  data.brandPolicies.policies.find(
                    ({ type }) => type === BrandPolicyType.INTERNATIONAL
                  )!
                }
                policyUnits={data.brandPolicies.policyUnits}
              />
            }
          />
        ) : (
          <BrandPolicies
            policies={data.brandPolicies.policies[0]}
            policyUnits={data.brandPolicies.policyUnits}
          />
        )}
      </div>
    );
  }

  return <></>;
};

export default Brand;
