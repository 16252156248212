import { ErrorPage, ErrorTypes } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Brand from "../../views/brand/Brand";
import Home from "../../views/home/Home";
import pathnames from "../pathnames/pathnames";

const Router = () => {
  return (
    <Routes>
      <Route path={pathnames.home} element={<Home />} />
      <Route path=":brand" element={<Brand />} />
      <Route path="*" element={<ErrorPage type={ErrorTypes.e404} />} />
    </Routes>
  );
};

export default Router;
